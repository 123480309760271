export default {
  "/": {
    title: "Michael Colonna",
    body: `
        <p>
        Michael is a Brooklyn-based software engineer and artist. He uses code and simple geometries to explore and describe his subjective experience. He is particularly interested in the potential of technology to shape society and unlock new forms of creative expression.
        </p>
        <p>
        He currently works as a software engineer at Uber, supporting some of the most critical backend services that power UberEats.
        </p>
    `,
    contentUrl: "",
  },
  "/sankhara": {
    title: "Sankhara",
    body: `
    <p>
    This piece is inspired by the Buddhist concept of impermanence. Notice how your thoughts and feelings come and go.
</p>
    `,
    contentUrl: "https://particle-experiment.glitch.me/",
  },
  "/synesthesia": {
    title: "Synesthesia",
    body: `
    <p>
I can't play an instrument. But I wish that I could. So I built an instrument that uses simple physics and a dead-simple visual interface to compose songs in physical, 2-D space. 
</p>
<p>
Try it. As someone who is not a musician, it makes me smile every time I open it up.
</p>
    `,
    contentUrl: "https://synesthetic-synth.glitch.me/",
  },
  "/simulacra": {
    title: "Simulacra",
    body: `
    <p>
    I'm addicted to my phone, just like everyone else. 
</p>
<p>
In this piece, I tried to capture the disembodied experience of scrolling by making the digital world embodied and concrete, through animation and mechanical foley. Do our digital spaces count as "Real", as valid spaces for us to inhabit?
</p>
<p>
This piece was meant to be shown at Brown University's CAVE, a four-sided cube made out of projector screens that creates an immersive VR environment in physical space.   
</p>
`,
    contentUrl:
      "https://drive.google.com/file/d/1CNmHM3HYzD6SdkCjwMqXOkJZgoZ-jznB/preview",
  },
};
