import React, { useState, useEffect, useRef } from "react";
import { HiChevronUp } from "react-icons/hi2";
import data from "./data";
import { useScramble } from "use-scramble";
import "./App.css";
import bio from "./img/bio.JPG";
import {
  useScroll,
  useMotionValueEvent,
  motion,
  AnimatePresence,
} from "framer-motion/dist/framer-motion";

// This enables us to listen to 'replaceState' events
var _wr = function (type) {
  var orig = window.history[type];
  return function () {
    var rv = orig.apply(this, arguments);
    var e = new Event(type);
    e.arguments = arguments;
    window.dispatchEvent(e);
    return rv;
  };
};
window.history.replaceState = _wr("replaceState");

const SCRAMBLE_SPEED = 1;

const TITLE_SCRAMBLE_PROPS = {
  speed: SCRAMBLE_SPEED,
  playOnMount: true,
};

const BODY_SCRAMBLE_PROPS = {
  speed: SCRAMBLE_SPEED,
  tick: 1,
  step: 8,
  seed: 5,
  playOnMount: true,
};

function getLastToken(pathname) {
  return pathname.substring(pathname.lastIndexOf("/"));
}

const scrollIntoView = (id) =>
  document.getElementById(id).scrollIntoView({ behavior: "smooth" });

function App() {
  const scrollColumnRef = useRef(null);

  useEffect(() => {
    if (getLastToken(window.location.pathname) !== "/") {
      scrollIntoView(getLastToken(window.location.pathname));
    }
  }, []);

  return (
    <div className="App">
      <LeftColumn scrollColumnRef={scrollColumnRef} />
      <NavBar />
      <RightColumn />
    </div>
  );
}

function NavBar() {
  return (
    <>
      <div className="nav-buffer" />
      <div className="nav">
        <ul className="menu">
          <li className="nav-item">
            <a onClick={() => scrollIntoView("/")}>Home</a>
          </li>
          <li className="nav-item sub-nav-container">
            <div className="sub-nav-title">
              Work <HiChevronUp className="chevron" />
            </div>
            <ul className="sub-nav">
              {Object.keys(data)
                .filter((url) => url !== "/")
                .map((url) => (
                  <li className="sub-nav-item">
                    <a onClick={() => scrollIntoView(url)}>{data[url].title}</a>
                  </li>
                ))}
            </ul>
          </li>
          <li className="nav-item">Resume</li>
        </ul>
      </div>
      <div className="nav-buffer" />
    </>
  );
}

function LeftColumn({ scrollColumnRef }) {
  return (
    <div className="column column-left" ref={scrollColumnRef}>
      {Object.keys(data).map((url, i) => (
        <Item
          key={i}
          title={data[url].title}
          body={data[url].body}
          url={url}
          includeBioImage={url === "/"}
          containerRef={scrollColumnRef}
        />
      ))}
    </div>
  );
}

function Item({ title, body, url, containerRef }) {
  const { ref: headerRef, replay: replayHeader } = useScramble({
    text: title,
    ...TITLE_SCRAMBLE_PROPS,
  });
  const { ref: bodyRef, replay: replayBody } = useScramble({
    text: body,
    ...BODY_SCRAMBLE_PROPS,
  });

  const ref = useRef(null);
  const [played, setPlayed] = useState(false);
  const { scrollYProgress } = useScroll({
    layoutEffect: false,
    container: containerRef,
    target: ref,
    offset: ["start 50vh", "end 50vh"],
  });

  useMotionValueEvent(scrollYProgress, "change", (latest) => {
    if (latest > 0) {
      window.history.replaceState(null, null, url);
      if (!played) {
        replayHeader();
        replayBody();
        setPlayed(true);
      }
    }
  });

  return (
    <div className="left-item" id={url} ref={ref}>
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 1 }}
        >
          <h1 ref={headerRef} />

          <p ref={bodyRef} />
        </motion.div>
      </AnimatePresence>
    </div>
  );
}

function RightColumn() {
  const [showPicture, setShowPicture] = useState(false);
  const [prevPathname, setPrevPathname] = useState(
    getLastToken(window.location.pathname)
  );

  if (!showPicture && prevPathname === "/") {
    setShowPicture(true);
  } else if (showPicture && prevPathname !== "/") {
    setShowPicture(false);
  }

  window.addEventListener("replaceState", (e) => {
    const newPathname = e.arguments[2];
    if (newPathname !== prevPathname) {
      setPrevPathname(newPathname);
    }
  });

  const content = data[prevPathname];

  return (
    <div className="column column-right">
      {showPicture ? (
        <motion.div
          key="picture"
          className="right-item"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          <img className="bio" src={bio} />
        </motion.div>
      ) : null}

      {!showPicture ? (
        <iframe src={content.contentUrl} allow="autoplay" />
      ) : null}
    </div>
  );
}

export default App;
